.menu-sider {
  height: calc(100vh - 64px);
  overflow: auto;
  position: fixed !important;
  left: 0;
  border-right: 1px solid @app-border-color !important;
}

.main-layout--app-collapsed {
  .menu-sider {
    margin-left: 300px;
    box-shadow: -10px 0 10px 1px rgba(0, 0, 0, 0.5) !important;
  }
}

@media screen and (max-width: @table) {
  .menu-sider {
    display: none;
    opacity: 0;
  }
}

@hack: true; @import "/private/var/www/sportix-v2/sportix-console/src/style/themes/theme.less";