//@import '~antd/lib/style/themes/dark.less';
@import '~antd/dist/antd.less';

.App {
  min-height: 100vh;
  display: flex;
  flex: auto;
  flex-direction: column;
}

@hack: true; @import "/private/var/www/sportix-v2/sportix-console/src/style/themes/theme.less";