// ANTD theme customization
@import '~antd/lib/style/themes/default';
@import '~antd/lib/style/color/colors.less';

// Suggested by antd to change theme, major variables
// check https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less for more vars

@primary-color: #404041; // primary color for all components #feba63
@secondary-color: lighten(@primary-color, 50%);
@link-color: #1473e6; // link color
@info-color: #1890ff;
@success-color: #4eb15b; // success state color
@warning-color: #faad14; // warning state color
@error-color: #EF4136; // error state color #f5222d
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(64, 64, 65, 0.25); // disable state color
@border-radius-base: 4px; // major border radius
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
@border-color-base: lighten(@primary-color, 15%);


//App-color
@app-color: #fff;
@app-accent: #FFDF6C;
@app-border-color: lighten(@primary-color, 20%);
@border-radius-base: 3px;
@main-background: #3e58cf;
@secondary-color: #363a41;
@third-color: #3c434a;
@background-color: #eef2f6;
@disable-input-bg: #f5f5f5;
@disable-placeholder-color: rgb(117, 117, 117);

//z-index
@zindex-message: 100010;
@zindex-modal: 100011;
@zindex-modal-mask: 100011;
@zindex-notification: 100011;
@zindex-popover: 100031;
@zindex-dropdown: 100050;
@zindex-picker: 100050;
@zindex-popoconfirm: 100060;
@zindex-tooltip: 100070;
@zindex-image: 100070;

//Media
@desktopLarge: 1680px;
@desktop: 1280px;
@desktopSmall: 1024px;
@tableBig: 960px;
@table: 767px;
@mobile: 320px;

//Badge
@zindex-badge: 100080 html,
body {
  height: 100%;
};

//Input

.ant-input-affix-wrapper {
  border-radius: @border-radius-base !important;
}

.ant-input {
  &[disabled] {
    color: @disable-placeholder-color
  }

  &.ant-input-disabled, .ant-input:placeholder-shown {
    color: @disable-placeholder-color
  }
}

//Picker

.ant-picker {
  &.ant-picker-disabled {
    color: @disable-placeholder-color
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-box-shadow: 0 0 0 50px @disable-input-bg inset;
}

input::-webkit-input-placeholder {
  color: @disable-placeholder-color !important;
}

//Select
@select-item-selected-color: #fff;
@select-item-selected-bg: #E24411;

//Phone input
.field-phone {
  width: 100% !important;
  height: 42px !important;
  background-color: transparent !important;
  border: 1px solid rgb(236, 236, 236) !important;
  border-radius: @border-radius-base;

  &[disabled] {
    border: 1px solid #000 !important;
    border-radius: @border-radius-base;
  }

  &--disabled.flag-dropdown{
    border: 1px solid #000 !important;
  }
}

.field-phone-search {
  background-color: @app-color !important;
  border: 1px solid rgb(236, 236, 236) !important;

  &-box {
    background-color: transparent !important;
  }
}

.selected-flag {
  background-color: transparent !important;
}

.flag-dropdown {
  border-color: rgb(236, 236, 236) !important;
  border-radius: @border-radius-base;
}

.country-list {
  background-color: @app-color !important;

  &:hover {
    background-color: @app-color !important;

    > span {
      color: @primary-color !important;
    }
  }
}




