.phone-container {
  .form-control {
    height: 32px !important;
    width: 100% !important;
  }
}

.field-phone {
  &--disabled {
    background-color: @disable-input-bg;
  }
}

.phone-input-component-container {
  position: relative;
}

@hack: true; @import "/private/var/www/sportix-v2/sportix-console/src/style/themes/theme.less";