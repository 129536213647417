.content {
  padding: 0 50px;
  transition: padding 0.2s;
  background-color: @background-color;

  &__wrap {
    background: transparent;
    min-height: 280px;
    max-width: 1400px;
    margin: 30px auto 0;
    transition: padding 0.2s;
  }
}


@media (max-width: 1200px) {
  .content {
    padding: 0 25px;
  }
}

@media (max-width: 992px) {
  .content {
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 0 10px;
  }
}

@media (max-width: 576px) {
  .content {
    padding: 0 3px;
  }

}

@hack: true; @import "/private/var/www/sportix-v2/sportix-console/src/style/themes/theme.less";